<template>
  <b-form-group>
    <label :for="id" v-if="withLabel"
      ><i v-if="isRequired" class="text-danger" style="">*</i> {{ $t(id) }}
    </label>
    <span
      v-if="showRemainChars && checkMaxLengthValidation()"
      :class="remainChar < 0 ? 'badge badge-danger' : 'badge badge-primary'"
      >{{ remainChar }}</span
    >
    <b-form-textarea
      :type="type"
      :id="id"
      :name="id"
      :value="value"
      @input="(val) => changed(val)"
      :state="validateState(id)"
      :aria-describedby="id + '-feedback'"
      :placeholder="$t(id)"
      :rows="rows"
      @blur="lostFocus()"
    ></b-form-textarea>
    <b-form-invalid-feedback :id="id + '-feedback'">{{
      $t(`${prename}Validator.${id}`)
    }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  name: "ValidationableTextArea",
  props: {
    id: { required: true, type: String },
    value: { required: true, type: String },
    form: { required: true, type: Object },
    type: { required: false, type: String, default: "text" },
    rows: { required: false, type: Number, default: 4 },
    prename: { required: false, type: String, default: "" },
    withLabel: { required: false, type: Boolean, default: true },
    isRequired: { type: Boolean, default: false },
    showRemainChars: { type: Boolean, default: false },
  },
  watch: {
    value() {
      if (this.showRemainChars) this.getRemainChars(this.value);
    },
  },
  data() {
    return { remainChar: 0 };
  },
  mounted() {
    this.getRemainChars("");
  },
  methods: {
    getRemainChars(val) {
      const maxLength = this.form.$params["maxLength"];
      if (this.showRemainChars && maxLength !== undefined) {
        const textLength = val != null ? val.length : 0;
        this.remainChar = maxLength.max - textLength;
      }
    },
    checkMaxLengthValidation() {
      const check = this.form.$params["maxLength"] === undefined;
      return !check;
    },
    changed(val) {
      this.getRemainChars(val);
      this.$emit("input", val);
    },
    validateState(name) {
      const { $dirty, $error } = this.form;
      return $dirty ? !$error : null;
    },
    lostFocus() {
      this.$emit("onLostFocus");
    },
  },
};
</script>