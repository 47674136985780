import { required, minLength, maxLength, numeric, minValue, maxValue } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            key: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50),
            },
            description: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(250),
            },
            googleId: {
                required
            },
            height: {
                required,
                minValue: 0,
                maxValue: 9000,
                numeric,
            },
            width: {
                required,
                minValue: 0,
                maxValue: 9000,
                numeric,
            },

        },
    }
}

export default { getValidations };