<template>
  <div class="animated fadeIn">
    <b-form @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col sm="6" md="6" xs="12" offset-sm="3">
          <b-card>
            <div slot="header">
              <strong>
                <i class="fa fa-shopping-cart"></i>
                {{ ads.id == 0 ? $t("newAds") : $t("updateAds") }}</strong
              >
            </div>

            <b-row>
              <b-col sm="12">
                <ValidationableTextBox
                  prename="ads"
                  id="key"
                  :isRequired="true"
                  icon="fa fa-key"
                  v-model="$v.form.key.$model"
                  :form="$v.form.key"
                />
              </b-col>
              <b-col sm="12">
                <ValidationableTextArea
                  prename="ads"
                  id="description"
                  :isRequired="true"
                  v-model="$v.form.description.$model"
                  :form="$v.form.description"
                  :value="$v.form.description"
                />
              </b-col>
              <b-col sm="4">
                <ValidationableTextBox
                  prename="ads"
                  id="height"
                  :isRequired="true"
                  type="number"
                  icon="fa fa-text-height"
                  v-model="$v.form.height.$model"
                  :form="$v.form.height"
                />
              </b-col>
              <b-col sm="4">
                <ValidationableTextBox
                  prename="ads"
                  id="width"
                  type="number"
                  :isRequired="true"
                  icon="fa fa-text-width"
                  v-model="$v.form.width.$model"
                  :form="$v.form.width"
                />
              </b-col>
              <b-col sm="4">
                <ValidationableTextBox
                  prename="ads"
                  id="googleId"
                  :isRequired="true"
                  icon="fa fa-google"
                  v-model="$v.form.googleId.$model"
                  :form="$v.form.googleId"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="text-right">
                <div class="form-actions">
                  <BrandButton
                    buttonVariant="success"
                    buttonIcon="fa fa-save"
                    @click="save"
                    :text="$t('save')"
                  />

                  <BrandButton
                    buttonVariant="danger"
                    buttonIcon="fa fa-undo"
                    @click="back"
                    :text="$t('back')"
                  />
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import http from "../../shared/http";
import stringHelper from "../../shared/string-helper";
import adsValidator from "../../validations/ads-validator";
import toast from "../../shared/toast-helper";
import BrandButton from "../../widgets/form/CustomBrandButton";
import CustomDropDown from "../../widgets/form/CustomDropDown";
import ValidationableTextBox from "../../widgets/form/ValidationableTextBox";
import ValidationableTextArea from "../../widgets/form/ValidationableTextArea";
import { validationMixin } from "vuelidate";

export default {
  name: "AdsAdd",
  components: {
    ValidationableTextBox,
    BrandButton,
    CustomDropDown,
    ValidationableTextArea,
  },
  mixins: [validationMixin],
  validations: adsValidator.getValidations(),
  methods: {
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return;

      let model = { ...this.ads, ...this.form };
      model.height = parseInt(model.height);
      model.width = parseInt(model.width);
      let path = model.id > 0 ? "Advertisements/update" : "Advertisements/add";
      http.post(path, model).then((result) => {
        if (result && result.success) {
          toast.success(result.message);
          this.$router.push({ path: "/ads-list" });
        }
      });
    },
    getUpdateForm(adsId) {
      let path = `Advertisements/getbyid?id=${adsId}`;
      http.get(path).then((ads) => {
        if (ads) {
          this.ads = ads;
          this.form.key = ads.key;
          this.form.description = ads.description;
          this.form.googleId = ads.googleId;
          this.form.height = ads.height;
          this.form.width = ads.width;
          this.form.active = ads.active;
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
  data() {
    return {
      form: {
        key: "",
        description: "",
        googleId: "",
        height: 0,
        width: 0,
        active: true,
      },
      ads: {
        id: 0,
        key: "",
        description: "",
        googleId: "",
        height: 0,
        width: 0,
        active: true,
      },
    };
  },
  created() {
    let adsId = this.$route.params.id ? this.$route.params.id : 0;
    if (adsId > 0) this.getUpdateForm(adsId);
  },
};
</script>
